<template>
  <div class="nestGroupDetail">
    <div class="left">
      <!-- <el-input placeholder="请输入内容" suffix-icon="el-icon-search" v-model="keyword"> </el-input> -->
      <el-tree :data="treedata" :highlight-current="true" node-key="id" :expand-on-click-node="false" :props="defaultProps" default-expand-all @node-click="handleNodeClick"></el-tree>
    </div>
    <div class="middel"></div>
    <div class="right" v-if="data">
      <Listcontainer :isAdd="false" :search="false" :pagination="pagination" @exportFile="exportFile" :uploadFile="uploadFilebtn" @fetch="loadData">
        <template v-slot:grid><Grid :Gridtype="1" :isBtnHidden="false" :GridData="data" /></template>
        <template v-slot:defalut> defalut-slot </template>
        <template v-slot:table>
          <Basetable :data="data" :columns="columns" :operations="operations" :multi="multi" />
        </template>
      </Listcontainer>
    </div>
  </div>
</template>

<script>
import Basetable from '@/components/table/base-table/base-table';
import Listcontainer from '@/components/list-container/list-container';
import Grid from '@/components/grid/index.vue';
export default {
  components: {
    Basetable,
    Listcontainer,
    Grid
  },
  data() {
    return {
      keyword: '',
      multipleSelection: [],
      uploadFilebtn: ['export', 'quit'],
      multi: false,
      columns: [
        {
          prop: 'name',
          label: '参数名称'
        },
        {
          prop: 'code',
          label: '参数代码'
        },
        {
          prop: 'valueType',
          label: '数据类型'
        },
        {
          prop: 'unit',
          label: '单位'
        },
        {
          prop: 'Alarmstatus',
          label: '报警属性'
        }
      ],
      operations: {
        text: true,
        handles: []
      },
      pagination: {
        total: 0,
        pageSize: 20,
        currentPage: 1
      },
      routerQuery: null,
      treedata: [],
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      parentId: '',
      groupId: ''
    };
  },
  mounted() {
    if (this.$route.query.data !== '[object Object]') {
      this.routerQuery = this.$route.query.data;
      localStorage.setItem('routerQuery', JSON.stringify(this.routerQuery));
    } else {
      this.routerQuery = JSON.parse(localStorage.getItem('routerQuery'));
    }
    this.getTree();
  },
  methods: {
    async getTree() {
      this.treedata = [];
      const { groupId } = this.routerQuery;
      this.parentId = groupId;
      const treeRes = await this.$apis.NestGroup.GroupDetails({ groupId });
      this.treedata.push(treeRes);
    },
    async loadData(item) {
      switch (this.type) {
        case 1:
          // eslint-disable-next-line no-case-declarations
          const { currentPage, pageSize } = item;
          // eslint-disable-next-line no-case-declarations
          let dataoption = {
            currentPage: currentPage || 1,
            currentPageSize: pageSize || 20,
            groupId: this.groupId
          };
          // eslint-disable-next-line no-case-declarations
          const infoRes = await this.$apis.NestGroup.basicGroupDetails(dataoption);
          this.pagination = {
            total: infoRes.total,
            pageSize: infoRes.currentPageSize,
            currentPage: infoRes.currentPage
          };
          infoRes.items.forEach(item => {
            item.Alarmstatus = item.isAlarm ? '是' : '否';
          });
          this.data = infoRes.items;
          break;
        case 2:
          // eslint-disable-next-line no-case-declarations
          const PublicinfoRes = await this.$apis.NestGroup.publicParams({ dataoption });
          this.pagination = {
            total: PublicinfoRes.total,
            pageSize: PublicinfoRes.currentPageSize,
            currentPage: PublicinfoRes.currentPage
          };
          PublicinfoRes.items.forEach(item => {
            item.Alarmstatus = item.isAlarm ? '是' : '否';
          });
          this.data = PublicinfoRes.items;
          break;
      }
    },
    async handleNodeClick(item) {
      if (item.groupType) {
        this.groupId = item.groupId;
        switch (item.groupType) {
          case 1:
            // eslint-disable-next-line no-case-declarations
            const infoRes = await this.$apis.NestGroup.basicGroupDetails({ groupId: item.groupId });
            this.pagination = {
              total: infoRes.total,
              pageSize: infoRes.currentPageSize,
              currentPage: infoRes.currentPage
            };
            infoRes.items.forEach(item => {
              item.Alarmstatus = item.isAlarm ? '是' : '否';
            });
            this.data = infoRes.items;
            this.groupId = item.groupId;
            this.type = 1;
            break;
          case 2:
            // eslint-disable-next-line no-case-declarations
            const PublicinfoRes = await this.$apis.NestGroup.publicParams({ groupId: item.groupId });
            this.pagination = {
              total: PublicinfoRes.total,
              pageSize: PublicinfoRes.currentPageSize,
              currentPage: PublicinfoRes.currentPage
            };
            PublicinfoRes.items.forEach(item => {
              item.Alarmstatus = item.isAlarm ? '是' : '否';
            });
            this.data = PublicinfoRes.items;
            this.groupId = item.groupId;
            this.type = 2;
            break;
        }
      }
    },
    async exportFile() {
      const res = await this.$apis.NestGroup.exportValidNestParam({ groupId: this.parentId });
      if (res) {
        this.$apis.NestGroup.exportNestParam({ groupId: this.parentId });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.nestGroupDetail {
  display: flex;
  width: 100%;
  height: 100%;
  .left {
    padding-right: 20px;
    width: 370px;
    height: 100%;
  }
  .middel {
    position: relative;
    bottom: 20px;
    width: 20px;
    height: calc(100% + 40px);
    background-color: #f3f3f3;
  }
  .right {
    padding-left: 20px;
    flex: 1;
    height: 100%;
  }
}
// /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
//   background-color: #f5f7fa;
//   color: #f39800;
// }
// /deep/ .el-tree-node .is-expanded .is-focusable {
//   height: 36px;
// }
// /deep/ .el-tree-node__content {
//   height: 36px;
// }
</style>
